import { Suspense, lazy, useEffect, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { removeUserData, saveUserData } from "./store/slices/auth-slices";
import "./assets/scss/style.scss";
import { setCurrency } from "./store/slices/currency-slice";
import { Helmet } from "react-helmet";
import i18n from "./i18n";
import { deleteFromWishlist } from "./store/slices/wishlist-slice";
import { deleteFromCart } from "./store/slices/cart-slice";

const ProfileMain = lazy(() => import("./pages/profile/profileMain"));
// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));
const HomeFashionFour = lazy(() => import("./pages/home/HomeFashionFour"));
const HomeFashionFive = lazy(() => import("./pages/home/HomeFashionFive"));
const HomeFashionSix = lazy(() => import("./pages/home/HomeFashionSix"));
const HomeFashionSeven = lazy(() => import("./pages/home/HomeFashionSeven"));
const HomeFashionEight = lazy(() => import("./pages/home/HomeFashionEight"));
const HomeKidsFashion = lazy(() => import("./pages/home/HomeKidsFashion"));
const HomeCosmetics = lazy(() => import("./pages/home/HomeCosmetics"));
const HomeFurniture = lazy(() => import("./pages/home/HomeFurniture"));
const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));
const HomeFurnitureThree = lazy(() =>
  import("./pages/home/HomeFurnitureThree")
);
const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));
const HomeFurnitureFive = lazy(() => import("./pages/home/HomeFurnitureFive"));
const HomeFurnitureSix = lazy(() => import("./pages/home/HomeFurnitureSix"));
const HomeFurnitureSeven = lazy(() =>
  import("./pages/home/HomeFurnitureSeven")
);
const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));
const HomeElectronicsTwo = lazy(() =>
  import("./pages/home/HomeElectronicsTwo")
);
const HomeElectronicsThree = lazy(() =>
  import("./pages/home/HomeElectronicsThree")
);
const HomeBookStore = lazy(() => import("./pages/home/HomeBookStore"));
const HomeBookStoreTwo = lazy(() => import("./pages/home/HomeBookStoreTwo"));
const HomePlants = lazy(() => import("./pages/home/HomePlants"));
const HomeFlowerShop = lazy(() => import("./pages/home/HomeFlowerShop"));
const HomeFlowerShopTwo = lazy(() => import("./pages/home/HomeFlowerShopTwo"));
const HomeOrganicFood = lazy(() => import("./pages/home/HomeOrganicFood"));
const HomeOrganicFoodTwo = lazy(() =>
  import("./pages/home/HomeOrganicFoodTwo")
);
const HomeOnepageScroll = lazy(() => import("./pages/home/HomeOnepageScroll"));
const HomeGridBanner = lazy(() => import("./pages/home/HomeGridBanner"));
const HomeAutoParts = lazy(() => import("./pages/home/HomeAutoParts"));
const HomeCakeShop = lazy(() => import("./pages/home/HomeCakeShop"));
const HomeHandmade = lazy(() => import("./pages/home/HomeHandmade"));
const HomePetFood = lazy(() => import("./pages/home/HomePetFood"));
const HomeMedicalEquipment = lazy(() =>
  import("./pages/home/HomeMedicalEquipment")
);
const HomeChristmas = lazy(() => import("./pages/home/HomeChristmas"));
const HomeBlackFriday = lazy(() => import("./pages/home/HomeBlackFriday"));
const HomeBlackFridayTwo = lazy(() =>
  import("./pages/home/HomeBlackFridayTwo")
);
const HomeValentinesDay = lazy(() => import("./pages/home/HomeValentinesDay"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const Privacy = lazy(() => import("./pages/other/Privacy"));
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const CheckoutBag = lazy(() => import("./pages/other/CheckoutBag"));
const PaymentSuccess = lazy(() => import("./pages/other/PaymentSuccess"));
const SubscriptionSuccess = lazy(() =>
  import("./pages/other/SubscriptionSuccess")
);
const FAQPage = lazy(() => import("./pages/other/FAQPage"));

//ADMIN
const AdminMainPage = lazy(() => import("./pages/AdminPanel/adminMainPage"));

//seller page
const SellerPage = lazy(() => import("./pages/sellerPage"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const ConfirmationPricesPage = lazy(() =>
  import("./pages/other/ProductsConfirmationProcess")
);
const RegisterPromotion = lazy(() => import("./pages/other/RegisterPromotion"));

// axios.defaults.baseURL = "http://localhost:4999";

const App = () => {
  const [isAuth, setIsAuth] = useState(false);
  const authData = useSelector((state) => state.auth);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [hideChat, setHideChat] = useState(false);

  useEffect(() => {
    dispatch(setCurrency("SEK"));
    const savedLanguage = localStorage.getItem("i18nLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  function combineArrays(array1, array2) {
    const combined = [...array1, ...array2];

    const merged = combined.reduce((acc, obj) => {
      const existingIndex = acc.findIndex((item) => item._id === obj._id);

      if (existingIndex !== -1) {
        // Merge objects with the same _id
        acc[existingIndex] = { ...acc[existingIndex], ...obj };
      } else {
        // Add new object
        acc.push(obj);
      }

      return acc;
    }, []);

    return merged;
  }

  const checkInactiveProducts = (items) => {
    const itemsProductIds = items.map((product) => product._id);
    axios
      .post("/lot/check-not-active-products", {
        ids: itemsProductIds,
      })
      .then((res) => {
        res.data?.inactiveProductIds.forEach((id) => {
          dispatch(deleteFromWishlist(id));
          dispatch(deleteFromCart(id));
        });
      });
  };

  useEffect(() => {
    const result = combineArrays(wishlistItems, cartItems);
    checkInactiveProducts(result);
  });

  useEffect(() => {
    const token = authData?.authData?.token;
    if (token != null) {
      axios.post("/users/verify/currentuser", { token: token }).then(
        (res) => {
          setIsAuth(true);
          if (
            res?.data?.result?.balance !==
              authData?.authData?.result?.balance ||
            res?.data?.result.freeBagUsed !==
              authData?.authData?.result?.freeBagUsed
          ) {
            dispatch(saveUserData(res.data));
          }
        },
        (err) => {
          setIsAuth(false);
          dispatch(removeUserData());
        }
      );
    } else {
      setIsAuth(false);
    }
  }, [authData]);

  useEffect(() => {
    const checkElementPresence = () => {
      const element = document.getElementById("side_nav_bar_custom");
      setHideChat(!!element);
    };
    checkElementPresence();
    const observer = new MutationObserver(() => {
      checkElementPresence();
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (hideChat) {
      const existingScript = document.getElementById("tidio-chat-iframe");
      if (existingScript) {
        existingScript.className = "hide-chat";
      }
    } else {
      const existingScript = document.getElementById("tidio-chat-iframe");
      if (existingScript) {
        existingScript.className = "";
      }
    }
  }, [hideChat]);

  return (
    <>
      <Helmet>
        {/* <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/145212035.js"
        /> */}
        {/* <script
          src="//code.tidio.co/ifvgk75ajjil4lfpz15bnmyuiknt83an.js"
          async
          className={hideChat ? "hide-chat" : ""}
        ></script> */}
      </Helmet>
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeFashion />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/profile"}
                element={
                  isAuth ? (
                    <MyAccount userData={authData} />
                  ) : (
                    <Navigate to="/login-register" replace={true} />
                  )
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/sell-profile"}
                element={isAuth ? <SellerPage /> : <LoginRegister />}
              />
              {/* Homepages */}
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion"}
                element={<HomeFashion />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-two"}
                element={<HomeFashionTwo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-three"}
                element={<HomeFashionThree />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-four"}
                element={<HomeFashionFour />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-five"}
                element={<HomeFashionFive />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-six"}
                element={<HomeFashionSix />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-seven"}
                element={<HomeFashionSeven />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-eight"}
                element={<HomeFashionEight />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-kids-fashion"}
                element={<HomeKidsFashion />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-cosmetics"}
                element={<HomeCosmetics />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture"}
                element={<HomeFurniture />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-two"}
                element={<HomeFurnitureTwo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-three"}
                element={<HomeFurnitureThree />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-four"}
                element={<HomeFurnitureFour />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-five"}
                element={<HomeFurnitureFive />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-six"}
                element={<HomeFurnitureSix />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-seven"}
                element={<HomeFurnitureSeven />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics"}
                element={<HomeElectronics />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics-two"}
                element={<HomeElectronicsTwo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics-three"}
                element={<HomeElectronicsThree />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-book-store"}
                element={<HomeBookStore />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-book-store-two"}
                element={<HomeBookStoreTwo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-plants"}
                element={<HomePlants />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-flower-shop"}
                element={<HomeFlowerShop />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-flower-shop-two"}
                element={<HomeFlowerShopTwo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-organic-food"}
                element={<HomeOrganicFood />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-organic-food-two"}
                element={<HomeOrganicFoodTwo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-onepage-scroll"}
                element={<HomeOnepageScroll />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-grid-banner"}
                element={<HomeGridBanner />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-auto-parts"}
                element={<HomeAutoParts />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-cake-shop"}
                element={<HomeCakeShop />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-handmade"}
                element={<HomeHandmade />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-pet-food"}
                element={<HomePetFood />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-medical-equipment"}
                element={<HomeMedicalEquipment />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-christmas"}
                element={<HomeChristmas />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-black-friday"}
                element={<HomeBlackFriday />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-black-friday-two"}
                element={<HomeBlackFridayTwo />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-valentines-day"}
                element={<HomeValentinesDay />}
              />
              {/* Shop pages */}
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                element={<ShopGridStandard />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                element={<ShopGridFilter />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                element={<ShopGridTwoColumn />}
              />
              <Route
                path={
                  process.env.PUBLIC_URL +
                  "/shop/:bodyType/:category/:subCategory/page/:page"
                }
                element={<ShopGridNoSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop/search/:title/page/:page"}
                element={<ShopGridNoSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                element={<ShopGridFullWidth />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                element={<ShopGridRightSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-standard"}
                element={<ShopListStandard />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                element={<ShopListFullWidth />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                element={<ShopListTwoColumn />}
              />
              {/* Shop product pages */}
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                element={<ProductTabLeft />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                element={<ProductTabRight />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                element={<ProductSticky />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-slider/:id"}
                element={<ProductSlider />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                element={<ProductFixedImage />}
              />
              {/* Blog pages */}
              <Route
                path={process.env.PUBLIC_URL + "/blog-standard"}
                element={<BlogStandard />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                element={<BlogNoSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                element={<BlogRightSidebar />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-details-standard"}
                element={<BlogDetailsStandard />}
              />
              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/privacy-policy"}
                element={<Privacy />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/faq"}
                element={<FAQPage />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/profile"}
                element={<MyAccount userData={authData} />}
              />
              <Route
                path={
                  process.env.PUBLIC_URL +
                  "/login-register/:activeTabKey/:statusCode"
                }
                element={
                  !isAuth ? (
                    <LoginRegister />
                  ) : (
                    <Navigate to="/profile" replace={true} />
                  )
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                element={
                  !isAuth ? (
                    <LoginRegister />
                  ) : (
                    <Navigate to="/profile" replace={true} />
                  )
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/register-promotion"}
                element={
                  !isAuth ? (
                    <RegisterPromotion />
                  ) : (
                    <Navigate to="/profile" replace={true} />
                  )
                }
              />
              <Route
                path={
                  process.env.PUBLIC_URL +
                  "/login-register/:activeTabKey/resetpassword/:token"
                }
                element={
                  !isAuth ? (
                    <LoginRegister />
                  ) : (
                    <Navigate to="/profile" replace={true} />
                  )
                }
              />
              {/* <Route
              path={
                process.env.PUBLIC_URL +
                "/login-register/:activeTabKey/:statusCode"
              }
              element={<LoginRegister />}
            /> */}
              {/* <Route
              path={process.env.PUBLIC_URL + "/login-register"}
              element={<LoginRegister />}
            /> */}
              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                element={<Wishlist />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                element={<Compare />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Checkout />}
              />
              {/* <Route
              path={process.env.PUBLIC_URL + "/checkout-bag"}
              element={<CheckoutBag />}
            /> */}
              <Route
                path={process.env.PUBLIC_URL + "/checkout-bag"}
                element={isAuth ? <CheckoutBag /> : <LoginRegister />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/not_found"}
                element={<NotFound />}
              />
              {/* <Route
              path={process.env.PUBLIC_URL + "/not_found_page"}
              element={
                !isAuth ? (
                  <LoginRegister />
                ) : (
                  <Navigate to="/sell-profile" replace={true} />
                )
              }
            /> */}
              <Route
                path={process.env.PUBLIC_URL + "/checkout-success"}
                element={<PaymentSuccess />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/subscription-success"}
                element={<SubscriptionSuccess />}
              />
              {isAuth && authData?.authData?.result?.role === "ADMIN" && (
                <Route
                  path={process.env.PUBLIC_URL + "/admindashboard"}
                  element={<AdminMainPage />}
                />
              )}
              <Route
                path={process.env.PUBLIC_URL + "/sell-profile"}
                element={<SellerPage />}
              />
              <Route
                path={
                  process.env.PUBLIC_URL +
                  "/confirm-prices/bag/:bagId/token/:token"
                }
                element={<ConfirmationPricesPage />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
