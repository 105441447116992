import React from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

const CookiesModal = ({ children }) => {
  const { t } = useTranslation();

  const handleReject = () => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    document.cookie = `myAwesomeCookieThriftys=; expires=${oneYearFromNow.toUTCString()}; path=/;`;
    localStorage.setItem("cookieConsent", "rejected");
  };

  const handleAccept = () => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 99);

    document.cookie = `myAwesomeCookieThriftys=; expires=${oneYearFromNow.toUTCString()}; path=/;`;
    localStorage.setItem("cookieConsent", "accepted");
  };

  return (
    <>
      {children}
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.accept")}
        cookieName="myAwesomeCookieThriftys"
        style={{ background: "#F0F4E0", color: "#604331", fontSize: 20 }}
        enableDeclineButton
        declineButtonText={t("cookies.reject")}
        buttonStyle={{
          color: "white",
          fontSize: "23px",
          background: "#604331",
        }}
        declineButtonStyle={{
          background: "#E0E0E0",
          color: "#604331",
          fontSize: "23px",
        }}
        onDecline={handleReject}
        onAccept={handleAccept}
        expires={365}
        overlay
      >
        {t("cookies.title")}
        <div style={{ fontSize: "12px" }}>
          {t("cookies.text")}{" "}
          <a
            style={{ color: "#604331", textDecoration: "underline" }}
            href={process.env.PUBLIC_URL + "/privacy-policy"}
          >
            {t("cookies.privacy")}
          </a>
          .
        </div>
      </CookieConsent>
    </>
  );
};

export default CookiesModal;
