const { createSlice } = require("@reduxjs/toolkit");

const bodyTypeSlice = createSlice({
  name: "bodyType",
  initialState: {
    bodyType: "women",
  },
  reducers: {
    changeBodyType(state, action) {
      state.bodyType = action.payload;
    },
  },
});

export const { changeBodyType } = bodyTypeSlice.actions;
export const selectBodyType = (state) => state.bodyType.bodyType;
export default bodyTypeSlice.reducer;
