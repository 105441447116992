const { createSlice } = require("@reduxjs/toolkit");

const announcementSlice = createSlice({
  name: "announcement",
  initialState: {
    showMessage: true,
  },
  reducers: {
    setShowMessage(state, action) {
      state.showMessage = !state.showMessage;
    },
  },
});

export const { setShowMessage } = announcementSlice.actions;
export default announcementSlice.reducer;
