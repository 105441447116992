import cogoToast from "cogo-toast";

const { createSlice } = require("@reduxjs/toolkit");

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authData: null,
    errorMessage: null,
  },
  reducers: {
    saveUserData(state, action) {
      state.authData = action.payload;
    },
    setUserError(state, action) {
      state.authData = null;
      state.errorMessage = action.payload;
      cogoToast.success(action.payload, { position: "bottom-left" });
    },
    removeUserData(state, action) {
      state.authData = null;
      state.errorMessage = null;
    },
  },
});

export const { saveUserData, setUserError, removeUserData } = authSlice.actions;
export default authSlice.reducer;
